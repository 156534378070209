import React, { useState } from "react"
import logoImg from "../assets/images/logoWhite.png"
import logoImgSm from "../assets/images/logoSquareWhite.png"

const Topbar = () => {
    const [drop, setDrop] = useState(false)

    const handleNavChange = () => {
        let doc = document.querySelector("html")
        if(doc.getAttribute("data-sidenav-size") == null) doc.setAttribute("data-sidenav-size", "condensed")
        else doc.removeAttribute("data-sidenav-size")
    }

    const handleModeChange = () => {
        let doc = document.querySelector("html")
        if(doc.getAttribute("data-bs-theme") == "light") doc.setAttribute("data-bs-theme", "dark")
        else doc.setAttribute("data-bs-theme", "light")
    }

    return (
        <div className="navbar-custom">
                <div className="topbar container-fluid">
                    <div className="d-flex align-items-center gap-1">
                        <button className="button-toggle-menu" onClick={handleNavChange}>
                            <i className="ri-menu-line"></i>
                        </button>

                        <button className="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>

                    <ul className="topbar-menu d-flex align-items-center gap-3">
                        <li className="dropdown d-lg-none">
                            <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                                <form className="p-3">
                                    <input type="search" className="form-control" placeholder="Search ..."
                                        aria-label="Recipient's username" />
                                </form>
                            </div>
                        </li>

                        <li className="d-none d-sm-inline-block" onClick={handleModeChange}>
                            <div className="nav-link" id="light-dark-mode">
                                <i className="ri-moon-line fs-22"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
    )
}

export default Topbar