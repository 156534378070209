import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { fetchOverview } from "../../actions/commonController"
import { toast } from "react-toastify"

const initialValue = {
    totalLeads: 0,
    totalCompletedLeads: 0,
    totalCompletedLeadsCurrentMonth: ""
}

const Overview = () => {
    const [loader, setLoader] = useState(true)
    const [overviewData, setOverviewData] = useState(initialValue)

    const loadData = async() => {
        try {
            setLoader(true)
            let { status, data } = await fetchOverview()
            console.log(status, data)
            if(status == "success") { setOverviewData(data) }
            setLoader(false)
        } catch (err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        loadData()
    }, [])
    

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">
                                Welcome!
                                <Button style={{ marginLeft: "10px" }} onClick={loadData}>Click here to refresh</Button>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxl-4 col-sm-12">
                        <div className="card widget-flat text-bg-pink">
                            <div className="card-body">
                                <div className="float-end">
                                    <i className="ri-eye-line widget-icon"></i>
                                </div>
                                <h6 className="text-uppercase mt-0" title="Customers">
                                    Total Leads
                                </h6>
                                <h2 className="my-2">
                                    {
                                        loader ?
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        : overviewData?.totalLeads
                                    } 
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-4 col-sm-12">
                        <div className="card widget-flat text-bg-purple">
                            <div className="card-body">
                                <div className="float-end">
                                    <i className="ri-wallet-2-line widget-icon"></i>
                                </div>
                                <h6 className="text-uppercase mt-0" title="Customers">
                                    Total Converted leads
                                </h6>
                                <h2 className="my-2">
                                    {
                                        loader ?
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        : overviewData?.totalCompletedLeads
                                    } 
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-4 col-sm-12">
                        <div className="card widget-flat text-bg-secondary">
                            <div className="card-body">
                                <div className="float-end">
                                    <i className="ri-wallet-2-line widget-icon"></i>
                                </div>
                                <h6 className="text-uppercase mt-0" title="Customers">
                                    Converted leads this month
                                </h6>
                                <h2 className="my-2">
                                    {
                                        loader ?
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        : overviewData?.totalCompletedLeadsCurrentMonth
                                    }
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview