import axios from "axios"

export const checkLogin = async (dt) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `http://192.168.0.88:3001/login`,
            data: { token: dt }
        })

        return {
            status: "success",
            message: response.data.message,
            token: response.data.token,
            tier: response.data.tier
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.message
        }
    }
}

export const checkAuth = async (token) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `http://192.168.0.88:3001/checkAuth`,
            data: { token }
        })

        return {
            status: "success",
            username: response.data.data.username,
            tier: response.data.data.tier,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}