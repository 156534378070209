import React, { createContext, useContext, useState } from 'react'
import { SetAuthorizationHeader, removeAuthorizationHeader } from '../config/axiosInterceptor'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false)
  const [token, setToken] = useState(null)
  const [tier, setTier] = useState(0)
  const [username, setUsername] = useState("")

  const login = async(newToken, tier, username) => {
    localStorage.setItem("token", newToken)

    setUsername(username)
    setIsLogin(true)
    setToken(newToken)
    setTier(tier)
    await SetAuthorizationHeader(newToken)
  }

  const logout = () => {
    localStorage.removeItem("token")

    setUsername("")
    setIsLogin(false)
    setToken(null)
    setTier(0)
    removeAuthorizationHeader()
  }

  return (
    <AuthContext.Provider value={{ isLogin, token, tier, username, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}