export const isEmpty = (data) => {
    return data === undefined || data === null || data === ''
}

export const dateParser = (data) => {
    let year = parseInt(data.substr(0, 4), 10)
    let month = parseInt(data.substr(5, 2), 10) - 1
    let day = parseInt(data.substr(8, 2), 10)
    let hour = parseInt(data.substr(11, 2), 10)
    let minute = parseInt(data.substr(14, 2), 10)
    let second = parseInt(data.substr(17, 2), 10)
    let millisecond = parseInt(data.substr(20, 3), 10)

    return new Date(Date.UTC(year, month, day, hour, minute, second, millisecond))
}

export const ExtractInitials = (fullName) => {
    let words = fullName.split(' ')
    let initials = words.map(word => word.charAt(0))
    return initials.join('').toUpperCase()
}