import React, { useState } from "react"
import logo from "../../assets/images/logo.png"
import { toast } from "react-toastify"
import CryptoJS from "crypto-js"
import { deleteAccount, deleteOtpSubmit } from "../../actions/commonController"

const secKey = process.env.REACT_APP_SECRET_TOKEN


const RemoveAccount = () => {
    let [loader, setLoader] = useState(false)
    let [email, setEmail] = useState("")
    let [otp, setOtp] = useState("")
    let [step, setStep] = useState(0)
 
    const handleEmailSubmit = async() => {
        if(email == "") return toast.error("Kindly fill in the email address to continue the process")

        let data = {
            email: email
        }
        let jsonData = JSON.stringify(data)
        let encryptionKey = secKey
        let encryptedData = CryptoJS.AES.encrypt(jsonData, encryptionKey).toString()

        setLoader(true)
        let { status, error } = await deleteAccount(encryptedData)

        if(status == "success") setStep(1)
        else toast.error(error)
        
        setLoader(false)
    }

    const handleOtpSubmit = async() => {
        if(otp == "") return toast.error("Kindly fill in the otp to continue the process")

        let data = {
            email: email,
            otp: otp
        }

        let jsonData = JSON.stringify(data)
        let encryptionKey = secKey
        let encryptedData = CryptoJS.AES.encrypt(jsonData, encryptionKey).toString()

        setLoader(true)
        let { status, error } = await deleteOtpSubmit(encryptedData)

        if(status == "success") setStep(2)
        else toast.error(error)
        
        setLoader(false)
    }

    return (
        <div className="authentication-bg position-relative">
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-lg-10">
                            <div className="card overflow-hidden">
                                <div className="row g-0">
                                    <div className="col-lg-12">
                                        <div className="d-flex flex-column h-100 position-relative">
                                            <div className="logo-light w-100 text-center pb-4 pt-5">
                                                <img src={logo} alt="logo" height="80" />
                                            </div>
                                            {
                                                step == 0 ?
                                                <>
                                                    <div className="px-4 py-3 my-auto">
                                                        <h5>Enter your email address of the account which you'd like to remove.</h5>
                                                        <h6><i>Note: Once you initiate the account deletion process, it cannot be reversed. All your personal information will be removed, except for the leads you have already submitted. Please ensure this is the action you want to take before proceeding.</i></h6>
                                                        </div>
                                                    <div className="px-4 my-auto pb-5">
                                                        <div className="mb-3">
                                                            <label for="emailaddress" className="form-label">Email address</label>
                                                            <input 
                                                                className="form-control" 
                                                                type="text" 
                                                                value={email}
                                                                placeholder="Enter your email address" 
                                                                onChange={(e) => {setEmail(e.target.value)}} />
                                                        </div>
                                                        <div className="mb-0 text-start">
                                                            {
                                                                loader ?
                                                                    <button className="btn btn-danger w-100" disabled>
                                                                        <span class="spinner-border spinner-border-sm me-1" role="status"
                                                                            aria-hidden="true"></span>
                                                                        Loading...
                                                                    </button>
                                                                :
                                                                    <button className="btn btn-soft-danger w-100" onClick={handleEmailSubmit}>
                                                                        <i className="ri-login-circle-fill me-1"></i> 
                                                                        <span className="fw-bold">Submit</span> 
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </> : step == 1 ?
                                                <>
                                                    <div className="p-4 my-auto pb-5">
                                                        <div className="mb-3">
                                                            <label for="emailaddress" className="form-label">Enter the OTP sent to your email address.</label>
                                                            <input 
                                                                className="form-control" 
                                                                type="number"
                                                                max={5}
                                                                value={otp}
                                                                placeholder="Enter your OTP code here" 
                                                                onChange={(e) => {setOtp(e.target.value)}} />
                                                        </div>
                                                        <div className="mb-0 text-start">
                                                            {
                                                                loader ?
                                                                    <button className="btn btn-danger w-100" disabled>
                                                                        <span class="spinner-border spinner-border-sm me-1" role="status"
                                                                            aria-hidden="true"></span>
                                                                        Loading...
                                                                    </button>
                                                                :
                                                                    <button className="btn btn-soft-danger w-100" onClick={handleOtpSubmit}>
                                                                        <i className="ri-login-circle-fill me-1"></i> 
                                                                        <span className="fw-bold">Delete my account</span> 
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </> : 
                                                <>
                                                    <div className="px-4 py-3 my-auto">
                                                        <h3>Your request for deleting {email} has been submitted!</h3>
                                                        <h3>All your content will be deleted within 24 hours. We are sorry to see you go, but will welcome you back whenever you see fit.</h3>
                                                        <h3>Take care, and all the best for your future endeavours.</h3>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoveAccount