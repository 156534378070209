import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
// import { fetchContactsLeads } from "../../actions/commonController"
import { Button, Modal } from "react-bootstrap"

const initialData = [
    {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "Buy Out",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "New Purchase",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "Buy Out",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "S/E - 2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Self Employed",
        interestRate: 5.29,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "Buy Out",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "New Purchase",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "Buy Out",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "S/E - 2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Self Employed",
        interestRate: 5.29,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "Buy Out",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "New Purchase",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "Buy Out",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "S/E - 2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Self Employed",
        interestRate: 5.29,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "Buy Out",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "Variable",
        bank: "SCB",
        type: "Variable",
        purchaseType: "New Purchase",
        employment: "Both",
        interestRate: 6.28,
        followOnRate: "0.95% + 3 Month EIBOR (Floor rate ...",
        fixedTerm: "--",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "Buy Out",
        employment: "Salaried",
        interestRate: 5.10,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
      {
        name: "S/E - 2 year fixed rate",
        bank: "SCB",
        type: "Fixed",
        purchaseType: "New Purchase",
        employment: "Self Employed",
        interestRate: 5.29,
        followOnRate: "1.50% + EIBOR (Floor rate 2.75%)",
        fixedTerm: "One To Two",
        isActive: "Active"
      },
]

const Products = () => {
    const [data, setData] = useState(initialData)
    const [modalLoader, setModalLoader] = useState(true)
    const [show, setShow] = useState(false)
    const [statusData, setStatusData] = useState({})
    
    const handleModal = () => setShow(true)
    const closeModal = () => setShow(false)

    const loadData = async() => {
        // try {
        //     setData({})
        //     let { status, data, error } = await fetchContactsLeads()
        //     if(status == "success") setData(data)
        // } catch (err) {
        //     toast.error(err.message)
        // }
    }


    useEffect(() => { loadData() }, [])

    return (
        <>
            <div className="row pt-3">
                <div className={`col-12 pt-`}>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="header-title m-0">
                                List of Products - Table
                                <Button style={{ marginLeft: "20px" }} onClick={handleModal}>Add new product</Button>
                            </h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className="row">
                                <table className="table table-striped dt-responsive nowrap w-100">
                                    <thead>
                                        <tr>
                                            <th>S. No</th>
                                            <th>Product</th>
                                            <th>Bank</th>
                                            <th>Type</th>
                                            <th>Purchase Type</th>
                                            <th>Employement</th>
                                            <th>Interest Rate</th>
                                            <th>Follow Rate</th>
                                            <th>Fixed Term</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data && data.length > 0 && data.map((item, index) => (
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.bank}</td>
                                                    <td>{item.type}</td>
                                                    <td>{item.purchaseType}</td>
                                                    <td>{item.employment}</td>
                                                    <td>{item.interestRate}</td>
                                                    <td>{item.followOnRate}</td>
                                                    <td>{item.fixedTerm}</td>
                                                    <td style={{alignContent:"center"}}>
                                                        <span class="badge bg-primary p-1 px-2">{item.isActive}</span>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-secondary me-2">
                                                            <i class="ri-hearts-fill me-1"></i> 
                                                            <span>Edit</span> 
                                                        </button>
                                                        <button type="button" class="btn btn-danger">
                                                            <i class="ri-rocket-line me-1"></i> 
                                                            <span>Inactive</span> 
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Register a new product in the system</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Product Name</label>
                                <input type="text" className="form-control" placeholder="Enter product name here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Bank Name</label>
                                <input type="text" className="form-control" placeholder="Enter bank name here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Type</label>
                                <input type="text" className="form-control" placeholder="Enter type here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Purchase Type</label>
                                <input type="text" className="form-control" placeholder="Enter purchase type here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Employement</label>
                                <input type="text" className="form-control" placeholder="Enter employement here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Interest Rate</label>
                                <input type="text" className="form-control" placeholder="Enter interest rate here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Follow Rate</label>
                                <input type="text" className="form-control" placeholder="Enter follow rate here" />
                            </div>
                            <div class="col-lg-6 mb-3">
                                <label className="form-label">Fixed Term</label>
                                <input type="text" className="form-control" placeholder="Enter fixed term here" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={() => {closeModal();toast.success("Product successfully saved!")}}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Products