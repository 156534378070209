import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Modal } from "react-bootstrap"
import Select from "react-select"
import uaeData from "../../dataset/locationData.json"
import { ExtractInitials } from "../../config/funcs"

const options = [
    { value: 'pakistani', label: 'Pakistani' },
    { value: 'indian', label: 'Indian' },
    { value: 'american', label: 'American' }
]

const dummyLead = [
    {
      agentName: "Muhamed Ali",
      name: "Danial Ahmed",
      phone: "971 55 123 4567",
      email: "danialahmed@live.com",
      status: "In Progress",
      created: "17 May 2024, 06:00 pm",
      updated: "17 May 2024, 06:00 pm"
    },
    {
      agentName: "John Smith",
      name: "Emma Watson",
      phone: "971 50 987 6543",
      email: "emma.watson@example.com",
      status: "Pending",
      created: "18 May 2024, 08:30 am",
      updated: "18 May 2024, 08:30 am"
    },
    {
      agentName: "Alice Johnson",
      name: "Michael Brown",
      phone: "971 56 111 2222",
      email: "michael.brown@example.com",
      status: "Completed",
      created: "18 May 2024, 10:15 am",
      updated: "18 May 2024, 10:15 am"
    },
    {
      agentName: "David Lee",
      name: "Sophia Garcia",
      phone: "971 58 333 4444",
      email: "sophia.garcia@example.com",
      status: "In Progress",
      created: "18 May 2024, 12:00 pm",
      updated: "18 May 2024, 12:00 pm"
    },
    {
      agentName: "Fatima Khan",
      name: "Noah Martinez",
      phone: "971 54 555 6666",
      email: "noah.martinez@example.com",
      status: "Pending",
      created: "18 May 2024, 02:45 pm",
      updated: "18 May 2024, 02:45 pm"
    },
    {
      agentName: "Elena Rodriguez",
      name: "Liam Wilson",
      phone: "971 52 777 8888",
      email: "liam.wilson@example.com",
      status: "Completed",
      created: "18 May 2024, 04:30 pm",
      updated: "18 May 2024, 04:30 pm"
    },
    {
      agentName: "Ahmed Khan",
      name: "Olivia Taylor",
      phone: "971 55 999 0000",
      email: "olivia.taylor@example.com",
      status: "In Progress",
      created: "18 May 2024, 06:15 pm",
      updated: "18 May 2024, 06:15 pm"
    },
    {
      agentName: "Jacob Brown",
      name: "Ava Johnson",
      phone: "971 50 222 3333",
      email: "ava.johnson@example.com",
      status: "Pending",
      created: "18 May 2024, 08:00 pm",
      updated: "18 May 2024, 08:00 pm"
    },
    {
      agentName: "Sophie Lee",
      name: "William Perez",
      phone: "971 56 444 5555",
      email: "william.perez@example.com",
      status: "Completed",
      created: "18 May 2024, 10:30 pm",
      updated: "18 May 2024, 10:30 pm"
    },
    {
      agentName: "Mohammed Ali",
      name: "Charlotte Brown",
      phone: "971 58 777 8888",
      email: "charlotte.brown@example.com",
      status: "In Progress",
      created: "19 May 2024, 12:15 am",
      updated: "19 May 2024, 12:15 am"
    }
  ]
  

const initialValue = {
    name: "",
    email: "",
    phone: "",
    empstatus: "",
    income: "",
    age: "",
    nationality: "",
    purchasetype: "",
    residencestatus: "",
    mortgagetype: "",
    loantype: "",
    fixedrate: "",
    mortgageterm: "",
    propertyvalue: "",
    loantovalue: "",
    city: "",
    area: "",
}
  

const Leads = () => {
    const [data, setData] = useState({})
    const [show, setShow] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [statusData, setStatusData] = useState({})
    const [formValue, setFormValue] = useState(initialValue)
    const { name, email, phone, empstatus, income, age, nationality,
        residencestatus, mortgagetype, loantype, fixedrate, mortgageterm, 
        purchasetype, propertyvalue, loantovalue, city, area } = formValue


    const handleChange = (e) => {
        let { name, value } = e.target

        setFormValue({
            ...formValue,
            [name]: value,
        })
    }

    const handleSelect = (e) => {
        let { name, value } = e.target

        setFormValue({
            ...formValue,
            [name]: value,
        })
    }

    const openModal = () => { setShow(true) }
    const closeModal = () => { setShow(false) }


    useEffect(() => { 
        // loadData() 
    }, [])

    return (
        <div className="row">
            <div className={`col-12 pt-`}>
                <div className="card">
                    <div className="card-header">
                        <h4 className="header-title m-0">
                            All Leads 
                            <Button style={{ width: "auto", marginLeft: "20px" }} onClick={openModal}>+  Create Lead</Button>
                        </h4>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <table className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Agent Name</th>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dummyLead && dummyLead.length > 0 ? dummyLead.map((item, index) => (
                                            <tr className="selectableTable">
                                                <td className="align-content-center">{index+1}</td>
                                                <td className="align-content-center">{item.agentName}</td>
                                                <td className="d-flex" style={{ minHeight: "80px" }}>
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title bg-primary-subtle text-secondary fs-20 rounded-circle">
                                                            {ExtractInitials(item.name)}
                                                        </span>
                                                    </div>
                                                    <div className="align-self-center ps-2">{item.name}</div>
                                                </td>
                                                <td className="align-content-center">{item.phone}</td>
                                                <td className="align-content-center">{item.email}</td>
                                                <td className="align-content-center">{item.status}</td>
                                                <td className="align-content-center">{item.created}</td>
                                                <td className="align-content-center">{item.updated}</td>
                                            </tr>
                                        )) : <td colSpan={8} className="text-center" style={{paddingTop:"50px"}}><h4>No Data</h4></td>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            
            <Modal size="xl" show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>    
                    <div className="card-body">
                        <h4 className="pb-2 w-100 text-center">Personal Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" name="name" value={name} onChange={handleChange} placeholder="Enter product name here" />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Email Address</label>
                                    <input type="text" className="form-control" name="email" value={email} onChange={handleChange} placeholder="Enter bank name here" />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Phone Number</label>
                                    <input type="text" className="form-control" name="phone" value={phone} onChange={handleChange} placeholder="Enter type here" />
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Employment Status</label>
                                    <select className="form-select" name="empstatus" value={empstatus} onChange={handleSelect}>
                                        <option value={""}>Choose an option</option>
                                        <option value={"employment"}>Employment</option>
                                        <option value={"self-employed"}>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Monthly Income</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="income" value={income} onChange={handleChange} placeholder="Income here" />
                                        <div className="input-group-text" data-password="false">
                                            AED
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 mb-3">
                                    <label className="form-label">Age</label>
                                    <input type="text" className="form-control" name="age" value={age} onChange={handleChange} placeholder="Age here" />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">Nationality</label>
                                    <Select className="customSelect" options={options} value={nationality} name="nationality" onChange={(e) => {setFormValue({...formValue,"nationality": e})}} />
                                </div>
                            </div>
                        </div>

                        <h4 className="pb-2 w-100 text-center">Lead Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-6 mb-3">
                                    <label className="form-label">Purchase Type</label>
                                    <select className="form-select" name="purchasetype" value={purchasetype} onChange={handleSelect}>
                                        <option>Select purchase type</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label className="form-label">Residence Status</label>
                                    <select className="form-select" name="residencestatus" value={residencestatus} onChange={handleSelect}>
                                        <option>Select purchase type</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Mortgage Type</label>
                                    <select className="form-select" name="mortgagetype" value={mortgagetype} onChange={handleSelect}>
                                        <option>Select mortgage type</option>
                                        <option>Fixed</option>
                                        <option>Variable</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Loan Type</label>
                                    <select className="form-select" name="loantype" value={loantype} onChange={handleSelect}>
                                        <option>Select loan type</option>
                                        <option>Conventional</option>
                                        <option>Islamic</option>
                                        <option>Top Deal</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Fixed Rate Term</label>
                                    <select className="form-select" name="fixedrate" value={fixedrate} onChange={handleSelect}>
                                        <option>Select purchase type</option>
                                        <option>Employment</option>
                                        <option>Self-employed</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Mortgage Term</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="mortgageterm" value={mortgageterm} onChange={handleChange} placeholder="Enter years here" />
                                        <div className="input-group-text" data-password="false">
                                            Years
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 className="pb-2 w-100 text-center">Property Details</h4>
                        <div className="card customCard p-3">
                            <div className="row">
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Property Value</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="propertyvalue" value={propertyvalue} onChange={handleChange} placeholder="Property value here" />
                                        <div className="input-group-text" data-password="false">
                                            AED
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Loan To Value</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" className="form-control" name="loantovalue" value={loantovalue} onChange={handleChange} placeholder="Loan rate here" />
                                        <div className="input-group-text" data-password="false">
                                            %
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">City</label>
                                    <select className="form-select" name="city" value={city} onChange={handleChange}>
                                        <option value="">Select city</option>
                                        {Object.keys(uaeData).map((city) => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <label className="form-label">Area</label>
                                    <select className="form-select" name="area" value={area} onChange={handleChange}>
                                        <option value={""}>Select area</option>
                                        {uaeData[city]?.map((ar) => (
                                            <option key={ar} value={ar}>
                                                {ar}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={() => {closeModal();toast.success("Lead successfully saved!")}}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Leads