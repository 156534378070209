import React from "react"

const ProfilePage = () => {
    return (
        <>
         Profile Page
        </>
    )
}

export default ProfilePage