import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './assets/css/app.css'
import './assets/css/icons.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/authContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <ToastContainer />
    <AuthProvider>
      <App />
    </AuthProvider>
  </>
)

reportWebVitals()