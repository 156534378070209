import React from "react"
import { Route } from "react-router-dom"
import AuthGuard from "../guards/authGuards"
import Overview from "../pages/Dashboard/overview"
import About from "../pages/About/About"
import Eibor from "../pages/EIBOR/Eibor"
import Products from "../pages/Product/Products"
import ProfilePage from "../pages/Profile/ProfileView"
import Leads from "../pages/Leads/OverallLeads"


const AuthRoutes = [
    <Route key="Dashboard" path="/dashboard" element={<AuthGuard component={<Overview />} />} />,
    <Route key="About" path="/about" element={<AuthGuard component={<About />} />} />,
    <Route key="Leads" path="/leads" element={<AuthGuard component={<Leads />} />} />,

    <Route key="EIBOR" path="/eibor" element={<AuthGuard component={<Eibor />} />} />,
    <Route key="Products" path="/products" element={<AuthGuard component={<Products />} />} />,
    <Route key="Profile" path="/profile" element={<AuthGuard component={<ProfilePage />} />} />,
]

export default AuthRoutes