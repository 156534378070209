import React, { useState } from "react"
import logo from "../../assets/images/logo.png"
import logoAuth from "../../assets/images/officeDesk.jpg"
import { useNavigate } from 'react-router-dom'
import { checkLogin } from "../../actions/authController"
import { loginValidation } from "./validation"
import { isEmpty } from "../../config/funcs"
import { toast } from "react-toastify"
import { useAuth } from "../../context/authContext"
import CryptoJS from "crypto-js"

const initialValue = {
    username: "",
    password: ""
}

const secKey = process.env.REACT_APP_SECRET_TOKEN


const Login = () => {
    let navigate = useNavigate()
    let [formValue, setFormValue] = useState(initialValue)
    let [validation, setValidation] = useState({})
    let [loader, setLoader] = useState(false)

    let { login } = useAuth()
    let { username, password } = formValue

    const handleChange = (e) => {
        let { name, value } = e.target
        setFormValue({
            ...formValue,
            [name]: value
        })
    }

    const handleSubmit = async() => {
        let formData = formValue
        let _error = loginValidation(formData)
        setValidation(_error)
        
        if(Object.keys(_error).length > 0 && !isEmpty(_error)) return

        let data = {
            username: formData.username,
            password: formData.password
        }

        let jsonData = JSON.stringify(data)
        let encryptionKey = secKey
        let encryptedData = CryptoJS.AES.encrypt(jsonData, encryptionKey).toString()

        setLoader(true)
        let { status, message, error, token, tier } = await checkLogin(encryptedData)

        if(status == "success") {
            await login(token, tier, formData.username)
            toast.success(message)
            navigate("/dashboard")
        } else {
            toast.error(error)
        }
        setLoader(false)
    }

    return (
        <div className="authentication-bg position-relative">
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-lg-10">
                            <div className="card overflow-hidden">
                                <div className="row g-0">
                                    <div className="col-lg-12">
                                        <div className="d-flex flex-column h-100 position-relative">
                                            <div className="logo-light w-100 text-center pb-4 pt-5">
                                                <img src={logo} alt="logo" height="80" />
                                            </div>
                                            <div className="p-4 my-auto pb-5">
                                                <div className="mb-3">
                                                    <label for="emailaddress" className="form-label">Username</label>
                                                    <input 
                                                        className="form-control" 
                                                        type="text" 
                                                        name="username"
                                                        value={username}
                                                        placeholder="Enter your username" 
                                                        onChange={handleChange} />
                                                    {
                                                        !isEmpty(validation?.username) && 
                                                            <div className="errorText">* This field is required</div>
                                                    }
                                                </div>
                                                <div className="mb-3">
                                                    <label for="password" className="form-label">Password</label>
                                                    <input 
                                                        className="form-control" 
                                                        type="password" 
                                                        name="password"
                                                        value={password}
                                                        placeholder="Enter your password" 
                                                        onChange={handleChange} />
                                                    {
                                                        !isEmpty(validation?.password) && 
                                                            <div className="errorText">* This field is required</div>
                                                    }
                                                </div>
                                                <div className="mb-0 text-start">
                                                    {
                                                        loader ?
                                                            <button className="btn btn-primary w-100" disabled>
                                                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                                                    aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                        :
                                                            <button className="btn btn-soft-primary w-100" onClick={handleSubmit}>
                                                                <i className="ri-login-circle-fill me-1"></i> 
                                                                <span className="fw-bold">Log In</span> 
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login