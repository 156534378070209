import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
// import { fetchContactsLeads } from "../../actions/commonController"
import { Button } from "react-bootstrap"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

const data = [
    {
      name: '1 Week',
      uv: 5.31,
      update: "15 May 2024, 15.05 pm"
    },
    {
      name: '1 Month',
      uv: 5.41,
      update: "15 May 2024, 15.05 pm"
    },
    {
      name: '3 Months',
      uv: 5.31,
      update: "15 May 2024, 15.05 pm"
    },
    {
      name: '6 Months',
      uv: 5.25,
      update: "15 May 2024, 15.05 pm"
    },
    {
      name: '1 Year',
      uv: 5.51,
      update: "15 May 2024, 15.05 pm"
    }
]

const Eibor = () => {
    // const [data, setData] = useState({})

    const loadData = async() => {
        // try {
        //     setData({})
        //     let { status, data, error } = await fetchContactsLeads()
        //     if(status == "success") setData(data)
        // } catch (err) {
        //     toast.error(err.message)
        // }
    }


    useEffect(() => { loadData() }, [])

    return (
        <div className="row pt-3">
            <div className={`col-12 pt-`}>
                <div className="card">
                    <div className="card-header">
                        <h4 className="header-title m-0">
                            EIBOR - Chart
                            <Button style={{ marginLeft: "10px" }} onClick={loadData}>Click here to refresh</Button>
                        </h4>
                    </div>
                    <div className="card-body pt-0">
                        <div style={{ width: '100%', height: 400, padding: "20px 0 0 0" }}>
                            <ResponsiveContainer>
                                <AreaChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis domain={[5.2, 5.6]} />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="uv" stroke="#004c3f" fill="#028835" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h4 className="header-title m-0">
                            EIBOR - Table
                        </h4>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <table className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th style={{width:"33%"}}>Name</th>
                                        <th style={{width:"33%"}}>Rate</th>
                                        <th style={{width:"33%"}}>Last Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 && data.map((item, index) => (
                                            <tr>
                                                <td>{item.name}</td>
                                                <td>{item.uv}</td>
                                                <td>{item.update}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Eibor