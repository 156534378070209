import axios from "axios"

export const fetchTotalLeads = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `http://192.168.0.88:3001/fetchTotalLeads`
        })
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchOverview = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `http://192.168.0.88:3001/fetchOverview`
        })

        console.log("check response:", response)
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}


export const deleteAccount = async (data) => {
    try {
        await axios({
            'method': 'get',
            'url': `http://192.168.0.88:3001/deleteAccount`,
            params: { data }
        })

        return {
            status: "success"
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const deleteOtpSubmit = async (data) => {
    try {
        await axios({
            'method': 'get',
            'url': `http://192.168.0.88:3001/deleteOtp`,
            params: { data }
        })

        return {
            status: "success"
        }
    } catch (err) {
        if(err.response.status == 401) {
            return {
                status: "failed",
                error: "Incorrect OTP entered. Please try again."
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}